import { QNAState } from './qnaState';
export const extraQnaInfoList = [
  {
    index: 0,
    question: '병원을 다녀온 이후 상태를 알려주세요',
    answerList: [
      '매우 안좋음(1)',
      '안좋음(2)',
      '보통(3)',
      '좋음(4)',
      '매우 좋음(5)',
    ],
    selectedAnswerList: [],
    type: 'point',
    page: 0,
    required: true,
    variable: 'checkup',
  },
  {
    index: 0,
    question: '아이 상태를 자세하게 작성해주세요',
    answerList: ['작성 해주신 상태를 기반으로 관련 콘텐츠를 드립니다.'],
    selectedAnswerList: [],
    type: 'text',
    page: 1,
    required: false,
    variable: 'hpi',
  },
];

export const reserveOperationQnaInfoList = [
  {
    index: 0,
    question:
      '혹시 현재 아이가 구토나 설사, 식욕부진 등의 임상증상이 있을 까요?',
    answerList: ['아니요! 없어요.', '네! 있어요.'],
    selectedAnswerList: [],
    type: 'select',
    page: 0,
    required: true,
    variable: '후유증(complication)',
  },
  {
    index: 0,
    question: '8시간의 금식 및 4시간의 금수가 필요합니다. 지켜주실 수 있죠?',
    answerList: ['네! 지킬게요.'],
    selectedAnswerList: [],
    type: 'select',
    page: 1,
    required: true,
    variable: '금식 및 금수 여부(fasting)',
  },
  {
    index: 0,
    question:
      '반려동물에게 북어 및 고 단백질의 간식 및 사료는 급여를 중지해주셔야합니다.',
    answerList: ['네! 안줄게요.'],
    selectedAnswerList: [],
    type: 'select',
    page: 2,
    required: true,
    variable: '사료 및 특이간식 섭취 중단여부(food)',
  },
];

export const registerOperationQnaInfoList = [
  {
    index: 0,
    question: '아이의 증상 혹은 상태를\n아래에서 모두 선택해 주세요!',
    answerList: [
      '구토를 했어요',
      '설사를 했어요',
      '너무 긁어요',
      '피부병이 생겼어요',
      '눈이 이상해요',
      '다리를 아파해요',
      '걸음걸이가 이상해요',
      '애기가 떨어요',
      '기침을 해요',
      '숨소리가 이상해요',
      '보기에 증상이 없어요',
      '상태가 괜찮아요',
    ],
    selectedAnswerList: [],
    type: 'symptoms',
    page: 0,
    required: true,
    variable: '주증상(cc)',
  },
  {
    index: 0,
    question: '금식이 잘 지켜졌나요?',
    answerList: ['네! 잘지켰어요.', '아니요! 지키지 못했어요.'],
    selectedAnswerList: [],
    type: 'select',
    page: 1,
    required: true,
    variable: '금식여부(fasting)',
  },
  {
    index: 0,
    question:
      '추가적으로 아이의 상태에 대해 병원에서 알아야할 상황이 있을까요?',
    answerList: [
      '미리 전달하지 못했거나 예약전 발생한 일이 있으면 알려주세요.',
    ],
    selectedAnswerList: [],
    type: 'text',
    page: 2,
    variable: '컨디션(hpi)',
  },
];
export const defaultQnaInfoList = [
  {
    index: 0,
    question: '아이의 증상 혹은 상태를\n아래에서 모두 선택해 주세요!',
    answerList: [
      '구토를 했어요',
      '설사를 했어요',
      '너무 긁어요',
      '피부병이 생겼어요',
      '눈이 이상해요',
      '다리를 아파해요',
      '걸음걸이가 이상해요',
      '애기가 떨어요',
      '기침을 해요',
      '숨소리가 이상해요',
      '보기에 증상이 없어요',
      '상태가 괜찮아요',
    ],
    selectedAnswerList: [],
    type: 'symptoms',
    page: 0,
    required: true,
    variable: '주증상(cc)',
  },
  {
    index: 0,
    question: '아이의 상태에 대해 점수를 매겨주세요',
    answerList: [
      '매우 안좋음(1)',
      '안좋음(2)',
      '보통(3)',
      '좋음(4)',
      '매우 좋음(5)',
    ],
    selectedAnswerList: [],
    type: 'point',
    page: 1,
    required: true,
    variable: 'checkup',
  },
  {
    index: 0,
    question: '주요 증상은 언제부터 시작됐는지 알려주세요.',
    answerList: [
      '방금 전',
      '하루 전',
      '이틀 전',
      '일주일 전',
      '자주 반복적으로 해요',
      '없어요',
    ],
    selectedAnswerList: [],
    type: 'select',
    page: 2,
    required: true,
    variable: '주증상 시작시점(onset)',
  },
  {
    index: 0,
    question: '주요 증상이 반복된 횟수를 알려주세요.',
    answerList: ['1회', '2회', '3회 이상', '주기적으로', '모르겠어요'],
    selectedAnswerList: [],
    type: 'select',
    page: 3,
    required: true,
    variable: '주증상 반복여부 및 횟수(repeat)',
  },
  {
    index: 0,
    question: '가장 최근에 동물병원에 방문한 시기를 알려주세요.',
    answerList: ['당일', '일주일 전', '한달 전', '6개월 전', '모르겠어요'],
    selectedAnswerList: [],
    type: 'select',
    page: 4,
    required: true,
    variable: '병원방문 내역(recent hospitalization)',
  },
  {
    index: 0,
    question:
      '아이의 상태에 대해서 추가적으로 병원이 알아야 하는 점을 작성해주세요.',
    answerList: ['자세한 설명은 진료에 더욱 도움이 됩니다 :)'],
    selectedAnswerList: [],
    type: 'text',
    page: 5,
    required: false,
    variable: '추가사항',
  },
];
const initialState: QNAState = {
  isQNAShow: false,
  qnaInfoList: defaultQnaInfoList,
};

export default initialState;
