import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';

import type { AppState } from '../../../app/store';
import { QNA } from '../component/QNADataType';
import initialState from './QNAInitialData';

export const QNASlice = createSlice({
  name: 'qna',
  initialState,
  reducers: {
    setQNAShow: (state, action: PayloadAction<boolean>) => {
      state.isQNAShow = action.payload;
    },
    setQNAInfoList: (state, action: PayloadAction<QNA[]>) => {
      state.qnaInfoList = action.payload;
    },
    addQNAInfo: (state, action: PayloadAction<QNA>) => {
      const removedState = state.qnaInfoList.filter((qna) => {
        return current(qna).question !== action.payload.question;
      });
      state.qnaInfoList = [...removedState, action.payload].sort(
        (a: { index: number }, b: { index: number }) => a.index - b.index,
      );
    },
  },
});

export const { setQNAShow, setQNAInfoList, addQNAInfo } = QNASlice.actions;
export const isQNAShow = (state: AppState) => state.qna.isQNAShow;
export const selectQNAInfoList = (state: AppState) => state.qna.qnaInfoList;
export default QNASlice.reducer;
