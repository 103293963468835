import { MessageState } from './chattingTypes';

const initialState: MessageState = {
  messages: [],
  isLoaded: false,
  waitingMessage: null,
  noContentsMessage: null,
  contentsMessageTemplates: [],
  currentChat: '',
  isShownTextAreaPopup: false,
  selectMessageData: {
    link: '',
    mediaType: 0,
  },
  isShownViewer: false,
  notificationMessages: [],
};

export default initialState;
