import '../styles/globals.css';

import type { AppProps } from 'next/app';
import { Head } from 'next/document';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { Provider } from 'react-redux';

import { useFirebase } from '../app/hooks';
import store from '../app/store';

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <meta
        name='viewport'
        content='width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no'
      />
      <title>채팅</title>
      <Provider store={store}>
        <Component {...pageProps} />
      </Provider>
    </>
  );
}

export default MyApp;
