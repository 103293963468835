import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';

import type { AppState } from '../../../app/store';
import initialState from './chattingInitialData';
import { MessageData, MessageState, SelectMessageData } from './chattingTypes';

export const chattingSlice = createSlice({
  name: 'chatting',
  initialState,
  reducers: {
    setMessages: (state, action: PayloadAction<MessageData[]>) => {
      state.messages = action.payload;
    },
    addMessages: (state, action: PayloadAction<MessageData[]>) => {
      let cloneMessages = _.cloneDeep(state.messages);
      cloneMessages = cloneMessages.concat(action.payload);
      // console.log('clone', cloneMessages);
      state.messages = _.uniqBy(cloneMessages, 'key');
    },
    setNoContentsMessage: (state, action: PayloadAction<any>) => {
      state.noContentsMessage = action.payload;
    },
    setWaitingMessage: (state, action: PayloadAction<any>) => {
      state.waitingMessage = action.payload;
    },
    setCurrentChat: (state, action: PayloadAction<string>) => {
      state.currentChat = action.payload;
    },
    setShowTextareaPopup: (state, action: PayloadAction<boolean>) => {
      state.isShownTextAreaPopup = action.payload;
    },
    setMessageData: (state, action: PayloadAction<SelectMessageData>) => {
      state.selectMessageData = action.payload;
    },
    setShowViewer: (state, action: PayloadAction<boolean>) => {
      state.isShownViewer = action.payload;
    },
    setLoaded: (state, action: PayloadAction<boolean>) => {
      state.isLoaded = action.payload;
    },
    setContentsMessageTemplates: (state, action: PayloadAction<any[]>) => {
      state.contentsMessageTemplates = action.payload;
    },
    setNotificationMessages: (state, action: PayloadAction<any[]>) => {
      state.notificationMessages = action.payload;
    },
  },
});

export const {
  setMessages,
  setCurrentChat,
  setWaitingMessage,
  setContentsMessageTemplates,
  setShowTextareaPopup,
  setMessageData,
  setShowViewer,
  setLoaded,
  setNoContentsMessage,
  setNotificationMessages,
  addMessages,
} = chattingSlice.actions;
export const selectMessages = (state: AppState) => state.chatting.messages;
export const selectWaitingMessage = (state: AppState) =>
  state.chatting.waitingMessage;
export const selectNoContentMessage = (state: AppState) =>
  state.chatting.noContentsMessage;
export const selectContentsMessageTemplates = (state: AppState) =>
  state.chatting.contentsMessageTemplates;
export const selectCurrentChat = (state: AppState) =>
  state.chatting.currentChat;
export const selectIsShownTextAreaPopup = (state: AppState) =>
  state.chatting.isShownTextAreaPopup;
export const selectSelectMessageData = (state: AppState) =>
  state.chatting.selectMessageData;
export const isShownViewer = (state: AppState) => state.chatting.isShownViewer;
export const isLoaded = (state: AppState) => state.chatting.isLoaded;
export const selectNotificationMessages = (state: AppState) =>
  state.chatting.notificationMessages;
export default chattingSlice.reducer;
