import { GlobalTypes } from './globalTypes';

const initialState: GlobalTypes = {
  auth: {
    currentUser: '',
    accessToken: '',
  },
  config: {
    loadingProgress: '',
    isLoaded: true,
    isChartCreateTime: '',
    isTimeOutMessage: false,
    isMessageTextAreaReadOnly: false,
    isShownContentsWebModal: false,
    isShownInformationModal: false,
    isShownA2HSModal: '',
    selectedRegisterModalIndex: 1,
    isShownReservationModal: false,
    isRegisterPromptEvent: false,
    isShownBanner: false,
    bannerMessage: undefined,
    uuid: '',
  },
  info: {
    hospitalKey: '',
    hospitalName: '',
    hospitalReportPhoneNumber: '',
    hospitalVirtualNumber: '',
    chatBotActive: true,
    chatBotFullTimeActive: true,
    chatBotActiveHoursInfo: undefined,
    activeChatBotOnPublicHoliday: true,
    hospitalTelNumber: '',
    chatKey: '',
    cycleKey: '',
    eventKey: '',
    isInstalledHomeScreen: undefined,
    isSubscribePush: undefined,
    lastCycleEventDate: 0,
    lastEventInfo: undefined,
    lastPurposeType: 'etc',
    lastCycleInfo: undefined,
    qnaType: 'visit',
    qnaList: null,
    tagList: [],
    familyInfo: {
      key: '',
      name: '',
      phoneNumber: '',
    },
    petInfo: {
      key: '',
      name: '',
      type: 0,
    },
    loadingText: '웹채팅 기다리는 중...',
    recentMessage: null,
    communicationKey: '',
  },
  timer: {
    min: 0,
    sec: 0,
  },
};

export default initialState;
