import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';

import type { AppState } from '../../app/store';
import { FamilyInfo, PetInfo } from '../base/baseInfo';
import { QNADataType } from '../qna/component/QNADataType';
import initialState from './globalInitialData';
import { TimerInterface } from './globalTypes';

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setLoadingProgress: (state, action: PayloadAction<string>) => {
      state.config.loadingProgress = action.payload;
    },
    setConfigIsShownBanner: (state, action: PayloadAction<boolean>) => {
      state.config.isShownBanner = action.payload;
    },
    setConfigSetBannerMessage: (state, action: PayloadAction<any>) => {
      state.config.bannerMessage = action.payload;
    },
    setConfigIsLoaded: (state, action: PayloadAction<boolean>) => {
      state.config.isLoaded = action.payload;
    },
    setInfoLoadingText: (state, action: PayloadAction<string>) => {
      state.info.loadingText = action.payload;
    },
    setConfigIsChartCreateTime: (state, action: PayloadAction<string>) => {
      state.config.isChartCreateTime = action.payload;
    },
    setConfigIsShownInformationModal: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.config.isShownInformationModal = action.payload;
    },
    setConfigIsShownA2HSModal: (state, action: PayloadAction<string>) => {
      state.config.isShownA2HSModal = action.payload;
    },
    setConfigIsRegisterPrompt: (state, action: PayloadAction<boolean>) => {
      state.config.isRegisterPromptEvent = action.payload;
    },
    setConfigUUID: (state, action: PayloadAction<string>) => {
      state.config.uuid = action.payload;
    },
    setInfoIsInstalledHomeScreen: (state, action: PayloadAction<boolean>) => {
      state.info.isInstalledHomeScreen = action.payload;
    },
    setInfoIsSubscribePush: (state, action: PayloadAction<boolean>) => {
      state.info.isSubscribePush = action.payload;
    },
    setConfigSelectedRegisterModalIndex: (
      state,
      action: PayloadAction<number>,
    ) => {
      state.config.selectedRegisterModalIndex = action.payload;
    },
    setConfigIsShownReservationModal: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.config.isShownReservationModal = action.payload;
    },
    setConfigIsTimeOutMessage: (state, action: PayloadAction<boolean>) => {
      state.config.isTimeOutMessage = action.payload;
    },
    setConfigIsShownContentsWebModal: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.config.isShownContentsWebModal = action.payload;
    },
    setConfigIsMessageTextAreaReadOnly: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.config.isMessageTextAreaReadOnly = action.payload;
    },
    setInfoHospitalKey: (state, action: PayloadAction<string>) => {
      state.info.hospitalKey = action.payload;
    },
    setInfoHospitalName: (state, action: PayloadAction<string>) => {
      state.info.hospitalName = action.payload;
    },
    setInfoHospitalTelNumber: (state, action: PayloadAction<string>) => {
      state.info.hospitalTelNumber = action.payload;
    },
    setInfoHospitalReportPhoneNumber: (
      state,
      action: PayloadAction<string>,
    ) => {
      state.info.hospitalReportPhoneNumber = action.payload;
    },
    setInfoHospitalVirtualNumber: (state, action: PayloadAction<string>) => {
      state.info.hospitalVirtualNumber = action.payload;
    },
    setChatBotActive: (state, action: PayloadAction<boolean>) => {
      state.info.chatBotActive = action.payload;
    },
    setInfoChatBotActiveHoursInfo: (
      state,
      action: PayloadAction<{ openTime: number; closeTime: number }>,
    ) => {
      state.info.chatBotActiveHoursInfo = action.payload;
    },
    setInfoChatBotFullTimeActive: (state, action: PayloadAction<boolean>) => {
      state.info.chatBotFullTimeActive = action.payload;
    },
    setInfoActiveChatBotOnPublicHoliday: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.info.activeChatBotOnPublicHoliday = action.payload;
    },
    setInfoChatKey: (state, action: PayloadAction<string>) => {
      state.info.chatKey = action.payload;
    },
    setInfoCycleKey: (state, action: PayloadAction<string>) => {
      state.info.cycleKey = action.payload;
    },
    setInfoEventKey: (state, action: PayloadAction<string>) => {
      state.info.eventKey = action.payload;
    },
    setInfoLastCycleInfo: (state, action: PayloadAction<any>) => {
      state.info.lastCycleInfo = action.payload;
    },
    setInfoLastCycleEventDate: (state, action: PayloadAction<number>) => {
      state.info.lastCycleEventDate = action.payload;
    },
    setInfoCommunicationKey: (state, action: PayloadAction<string>) => {
      state.info.communicationKey = action.payload;
    },
    setInfoLastEventInfo: (state, action: PayloadAction<any>) => {
      state.info.lastEventInfo = action.payload;
    },
    setInfoLastPurposeType: (
      state,
      action: PayloadAction<
        'consulting' | 'vaccine' | 'operation' | 'beauty' | 'etc'
      >,
    ) => {
      state.info.lastPurposeType = action.payload;
    },
    setInfoQnaType: (
      state,
      action: PayloadAction<'visit' | 'follow' | 'operation'>,
    ) => {
      state.info.qnaType = action.payload;
    },
    setInfoQnaList: (state, action: PayloadAction<QNADataType[] | null>) => {
      state.info.qnaList = action.payload;
    },
    setInfoTagList: (state, action: PayloadAction<string[] | null>) => {
      state.info.tagList = action.payload;
    },
    setInfoFamilyInfo: (state, action: PayloadAction<FamilyInfo>) => {
      if (!_.isEqual(state.info.familyInfo, action.payload)) {
        state.info.familyInfo = action.payload;
      }
    },
    setInfoPetInfo: (state, action: PayloadAction<PetInfo>) => {
      if (state.info.petInfo.key !== action.payload.key) {
        state.info.petInfo = action.payload;
      }
    },
    setTimer: (state, action: PayloadAction<TimerInterface>) => {
      state.timer = action.payload;
    },
    setCurrentUser: (state, action: PayloadAction<string>) => {
      state.auth.currentUser = action.payload;
    },
    setAccessToken: (state, action: PayloadAction<string>) => {
      state.auth.accessToken = action.payload;
    },
    setRecentMessage: (state, action: PayloadAction<any>) => {
      state.info.recentMessage = action.payload;
    },
  },
});

export const globalReducers = globalSlice.actions;
export const selectGlobalConfig = (state: AppState) => state.global.config;
export const selectGlobalInfo = (state: AppState) => state.global.info;
export const selectGlobalTimer = (state: AppState) => state.global.timer;
export const selectGlobalAuth = (state: AppState) => state.global.auth;
export default globalSlice.reducer;
