import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';

import globalReducer from '../features/@globalSlices/globalSlice';
import chattingReducer from '../features/chatting/slices/chattingSlice';
import qnaReducer from '../features/qna/slice/QNASlice';

export function makeStore() {
  return configureStore({
    reducer: {
      global: globalReducer,
      chatting: chattingReducer,
      qna: qnaReducer,
    },
  });
}

const store = makeStore();

export type AppState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action<string>
>;

export default store;
